import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";

export default function Header() {
  // Change nav color when scrolling
  const [meBgColor, setMeBgColor] = useState(false);
  const [activeSubMenuName, setActiveSubMenuName] = useState("");

  const menuChangColor = () => {
    if (window.scrollY >= 90) {
      setMeBgColor(true);
    } else {
      setMeBgColor(false);
    }
  };
  window.addEventListener("scroll", menuChangColor);
  const mobileNavToogle = () => {
    document.querySelector("body").classList.toggle("mobile-nav-active");
    document.querySelector(".mobile-nav-show").classList.toggle("d-none");
    document.querySelector(".mobile-nav-hide").classList.toggle("d-none");
  };

  const toggleSubMenuName = (e, menuName) => {

    e.stopPropagation();
    if (activeSubMenuName === menuName) {
      setActiveSubMenuName("");
    } else {
      setActiveSubMenuName(menuName);
    }
  };

  return (
    <>
      <header
        className={
          meBgColor
            ? "header d-flex align-items-center sticked stikcy-menu"
            : "header d-flex align-items-center sticked"
        }
      >
        <div className="container-fluid container-xl d-flex align-items-center justify-content-between">
          <Link to="/" className="logo d-flex align-items-center">
            <img src={"/assets/images/logo.png"} alt="logo" />
          </Link>
          <nav id="navbar" className="navbar" onClick={mobileNavToogle}>
            <ul>
              <li>
                <NavLink to="/">Home</NavLink>
              </li>
              <li>
                <NavLink to="/about-us">About Us</NavLink>
              </li>
              <li>
                <NavLink to="/services">Services</NavLink>
              </li>
              <li className="dropdown">
                <a
                  href="#"
                  className={activeSubMenuName === 'first' ? "active" : ""}
                  onClick={(e) => {
                    toggleSubMenuName(e, "first");
                  }}
                >
                  <span>Solutions</span>
                  <i
                    className={
                      activeSubMenuName === 'first'
                        ? "bi dropdown-indicator bi-chevron-up"
                        : "bi bi-chevron-down dropdown-indicator"
                    }
                  ></i>
                </a>
                <ul className={activeSubMenuName === 'first' ? "dropdown-active" : ""}>
                  <li>
                    <NavLink to="/solutions/healthcare-lifesciences">
                      Healthcare & Lifesciences
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/solutions/banking-finance-insurance">
                      Banking, Finance & Insurance
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/solutions/e-commerce-solutions">
                      E-Commerce Solutions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/solutions/on-demand-delivery">
                      On Demand Delivery
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/solutions/real-estate">Real Estate</NavLink>
                  </li>
                  <li>
                    <NavLink to="/solutions/transport-logistics">
                      Transport & Logistics
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/solutions/education-eLearning">
                      Education & eLearning
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/solutions/enterprise-retail-manufacturing">
                      Enterprise Retail & Manufacturing
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/solutions/travel-hospitality">
                      Travel & Hospitality
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/solutions/media-entertainment">
                      Media & Entertainment
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/solutions/social-networking">
                      Social Networking
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to="/portfolio">Portfolio</NavLink>
              </li>
              {/* <li>
                <NavLink to="/testimonials">Testimonials</NavLink>
              </li> */}
              <li className="dropdown">
                <a
                  href="#"
                  className={activeSubMenuName === 'second' ? "active" : ""}
                  onClick={(e) => {
                    toggleSubMenuName(e, "second");
                  }}
                >
                  <span>Others</span>
                  <i
                    className={
                      activeSubMenuName === 'second'
                        ? "bi dropdown-indicator bi-chevron-up"
                        : "bi bi-chevron-down dropdown-indicator"
                    }
                  ></i>
                </a>
                <ul className={activeSubMenuName === 'second' ? "dropdown-active" : ""}>
                  {/* <li>
                    <NavLink to="/pricing">Pricing</NavLink>
                  </li> */}
                  <li>
                    <NavLink to="/why-choose-us">Why Us?</NavLink>
                  </li>
                  <li>
                    <NavLink to="/terms-conditions">
                      Terms &amp; Conditions
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/privacy-policy">Privacy Policy</NavLink>
                  </li>
                  <li>
                    <NavLink to="/teams">Teams</NavLink>
                  </li>
                  <li>
                    <NavLink to="/blogs">BLOGS</NavLink>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          {/* <!-- .navbar --> */}
          <NavLink to="/contact" className="btn-get-started">
            Let's Talk
          </NavLink>
          <i
            className="mobile-nav-toggle mobile-nav-show bi bi-list"
            onClick={mobileNavToogle}
          />
          <i
            className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"
            onClick={mobileNavToogle}
          />
        </div>
      </header>
    </>
  );
}
