import React, { useEffect } from "react";

export default function Pricing() {
  const particle = React.useRef(null);

  React.useEffect(() => {
    window.particlesJS("particles-js", {
      particles: {
        number: {
          value: 150,
          density: {
            enable: true,
            value_area: 4000,
          },
        },
        color: {
          value: "#ffffff",
        },
        shape: {
          type: "circle",
          stroke: {
            width: 0,
            color: "#000000",
          },
          polygon: {
            nb_sides: 5,
          },
          image: {
            src: "img/github.svg",
            width: 100,
            height: 100,
          },
        },
        opacity: {
          value: 0.5,
          random: false,
          anim: {
            enable: false,
            speed: 1,
            opacity_min: 0.1,
            sync: false,
          },
        },
        size: {
          value: 6,
          random: true,
          anim: {
            enable: false,
            speed: 40,
            size_min: 0.1,
            sync: false,
          },
        },
        line_linked: {
          enable: true,
          distance: 150,
          color: "#ffffff",
          opacity: 0.4,
          width: 1,
        },
        move: {
          enable: true,
          speed: 6,
          direction: "none",
          random: false,
          straight: false,
          out_mode: "out",
          bounce: false,
          attract: {
            enable: false,
            rotateX: 600,
            rotateY: 1200,
          },
        },
      },
      interactivity: {
        detect_on: "canvas",
        events: {
          onhover: {
            enable: true,
            mode: "grab",
          },
          onclick: {
            enable: true,
            mode: "push",
          },
          resize: true,
        },
        modes: {
          grab: {
            distance: 140,
            line_linked: {
              opacity: 1,
            },
          },
          bubble: {
            distance: 400,
            size: 40,
            duration: 2,
            opacity: 8,
            speed: 3,
          },
          repulse: {
            distance: 200,
            duration: 0.4,
          },
          push: {
            particles_nb: 4,
          },
          remove: {
            particles_nb: 2,
          },
        },
      },
      retina_detect: true,
    });
  });

  // When click url then show page top section
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div className="breadcrumbs">
        <div className="page-header d-flex align-items-center">
          <div ref={particle} id="particles-js"></div>
          <div className="container position-relative">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 text-center">
                <h2 data-aos="fade-up">Pricing</h2>
                <p data-aos="fade-up" data-aos-delay="400">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pricing section">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card text-center">
                  <div className="title">
                    <img
                      src="/assets/images/icons/pricing-basic.png"
                      alt="icon"
                    />
                    <h2>Basic</h2>
                  </div>
                  <div className="price">
                    <h4>
                      <sup>$</sup>25
                    </h4>
                  </div>
                  <div className="option">
                    <ul>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited GB Space
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        30 Domain Names
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free SSL
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Daily Backup
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Templates
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Email
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        10 Databases
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited Email Address
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Live Support
                      </li>
                    </ul>
                  </div>
                  <a href="#">Order Now</a>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card text-center">
                  <div className="title">
                    <img
                      src="/assets/images/icons/pricing-standard.png"
                      alt="icon"
                    />
                    <h2>Standard</h2>
                  </div>
                  <div className="price">
                    <h4>
                      <sup>$</sup>50
                    </h4>
                  </div>
                  <div className="option">
                    <ul>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited GB Space
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        30 Domain Names
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free SSL
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Daily Backup
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Templates
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Email
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        10 Databases
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited Email Address
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Live Support
                      </li>
                    </ul>
                  </div>
                  <a href="#">Order Now</a>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card text-center">
                  <div className="title">
                    <img
                      src="/assets/images/icons/pricing-premium.png"
                      alt="icon"
                    />
                    <h2>Premium</h2>
                  </div>
                  <div className="price">
                    <h4>
                      <sup>$</sup>100
                    </h4>
                  </div>
                  <div className="option">
                    <ul>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited GB Space
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        30 Domain Names
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free SSL
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Daily Backup
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Templates
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Email
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        10 Databases
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited Email Address
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Live Support
                      </li>
                    </ul>
                  </div>
                  <a href="#">Order Now</a>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-3">
                <div className="card text-center">
                  <div className="title">
                    <img
                      src="/assets/images/icons/pricing-custom.png"
                      alt="icon"
                    />
                    <h2>Custom</h2>
                  </div>
                  <div className="price">
                    <h4>
                      <sup>$</sup>500
                    </h4>
                  </div>
                  <div className="option">
                    <ul>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited GB Space
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        30 Domain Names
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free SSL
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Daily Backup
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Templates
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Free Email
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        10 Databases
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Unlimited Email Address
                      </li>
                      <li>
                        <i
                          className="bi bi-check-circle"
                          aria-hidden="true"
                        ></i>{" "}
                        Live Support
                      </li>
                    </ul>
                  </div>
                  <a href="#">Order Now</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
