export const PortfolioData = [
    {
        'id':1,
        'category_id': "web_design",
        'category': "Web Design",
        'title':"Web Design",
        'image':"/assets/images/portfolio/web-design-sm-1.jpg",
        'lg_image':"/assets/images/portfolio/web-design-1.jpg",
        'url':"https://demoweb.aqdam.com/",
    },
    {
        'id':2,
        'category_id': "web_development",
        'category': "Web Development",
        'title':"Web Development",
        'image':"/assets/images/portfolio/web-development-sm.jpg",
        'lg_image':"/assets/images/portfolio/web-development.jpg",
        'url':"https://testsoft.citysecuritysa.com/csc-admin/home/",
    },
    {
        'id':3,
        'category_id': "app_design",
        'category': "App Design",
        'title':"App Design",
        'image':"/assets/images/portfolio/app-design-sm.jpg",
        'lg_image':"/assets/images/portfolio/app-design.jpg",
        'url':"#",
    },
    {
        'id':4,
        'category_id': "app_development",
        'category': "App Development",
        'title':"App Development",
        'image':"/assets/images/portfolio/product-small-1.jpg",
        'lg_image':"/assets/images/portfolio/large.jpg",
        'url':"#",
    },
    {
        'id':5,
        'category_id': "itsolution",
        'category': "Itsolution",
        'title':"IT Solution",
        'image':"/assets/images/portfolio/it-solution-sm.jpg",
        'lg_image':"/assets/images/portfolio/it-solution.jpg",
        'url':"#",
    },
    {
        'id':6,
        'category_id': "app_design",
        'category': "App Design",
        'title':"App Design",
        'image':"/assets/images/portfolio/app-design-sm-1.jpg",
        'lg_image':"/assets/images/portfolio/app-design-1.jpg",
        'url':"#",
    },
    {
        'id':7,
        'category_id': "web_design",
        'category': "Web Design",
        'title':"Web Design",
        'image':"/assets/images/portfolio/web-design-sm.jpg",
        'lg_image':"/assets/images/portfolio/web-design.jpg",
        'url':"https://testsoft.citysecuritysa.com/home/",
    },
    {
        'id':8,
        'category_id': "web_development",
        'category': "Web Development",
        'title':"Web Development",
        'image':"/assets/images/portfolio/web-development-sm-1.jpg",
        'lg_image':"/assets/images/portfolio/web-development-1.jpg",
        'url':"https://testsoft.citysecuritysa.com/csc-admin/home/",
    },
    {
        'id':9,
        'category_id': "web_design",
        'category': "Web Design",
        'title':"Web Design",
        'image':"/assets/images/portfolio/web-design-sm-2.jpg",
        'lg_image':"/assets/images/portfolio/web-design-2.jpg",
        'url':"https://demo.aqdam.com/",
    },
]